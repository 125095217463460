import PocketBase from "pocketbase";
import { useEffect, useState } from "preact/hooks";

const url = new URL(location.origin);
url.host = `pocketbase.${url.host}`;
export const pb = new PocketBase(url.toString());
window.pb = pb;

export async function loginGoogle() {
  const res = await pb.collection("users").authWithOAuth2({
    provider: "google",
  });
  try {
    // guardar nombre de google en pocketbase
    await pb.collection("users").update(res.record.id, { name: res.meta.name });
  } catch {
    // meh
  }
}

export function useUser() {
  const [user, setUser] = useState(pb.authStore.model);

  useEffect(() => {
    return pb.authStore.onChange((_, model) => setUser(model));
  });

  return [user];
}
