import { Controller } from "@hotwired/stimulus";
import L, { LeafletMouseEvent } from "leaflet";
import { h, render } from "preact";
import { useEffect, useState } from "preact/hooks";
import { parseISO, formatRelative } from "date-fns";
import { es } from "date-fns/locale";
import { loginGoogle, pb, useUser } from "../pocketbase";

const locationIcon = `<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="-5 -5 400 400" width="32" height="32"><path fill="rgb(20 20 20)" stroke="rgb(237 237 237)" stroke-width="16" d="M197.849 0C122.131 0 60.531 61.609 60.531 137.329c0 72.887 124.591 243.177 129.896 250.388l4.951 6.738a3.064 3.064 0 0 0 2.471 1.255 3.08 3.08 0 0 0 2.486-1.255l4.948-6.738c5.308-7.211 129.896-177.501 129.896-250.388C335.179 61.609 273.569 0 197.849 0m0 88.138c27.13 0 49.191 22.062 49.191 49.191 0 27.115-22.062 49.191-49.191 49.191-27.114 0-49.191-22.076-49.191-49.191 0-27.129 22.076-49.191 49.191-49.191"/></svg>`;
const locationIconUri = `data:image/svg+xml,${locationIcon}`;

interface Marker {
  id: string;
  image_uuid: string;
  x: number;
  y: number;
  creadorx: string;
}
interface Comment {
  id: string;
  created: string;
  updated: string;
  texto: string;
  creadorx: string;
  expand?: { creadorx?: { name?: string; username: string } };
}

export default class NonGraphicalMapController extends Controller {
  static values = {
    layer: String,
    attribution: String,
    uuid: String,
    i18nCreateMarker: String,
    maxZoomLevel: Number,
    width: Number,
    height: Number,
  };
  declare layerValue: string;
  declare attributionValue: string;
  declare uuidValue: string;
  declare i18nCreateMarkerValue: string;
  static targets = ["map", "addingOverlay"];
  declare readonly mapTarget: HTMLElement;
  declare readonly addingOverlayTarget: HTMLElement;
  private _layer: any;
  private _map: L.Map;
  private lastMarkers: L.Marker[] = [];

  connect() {
    this.fetchAndAddMarkers();

    render(<CreateMarkerOverlay controller={this} />, this.addingOverlayTarget);
  }

  async fetchAndAddMarkers() {
    const { items } = await pb
      .collection<Marker>("marcados")
      .getList(1, 10000, {
        filter: pb.filter("imagen_uuid = {:uuid}", {
          uuid: this.uuidValue,
        }),
      });
    this.lastMarkers.forEach((m) => m.remove());
    this.lastMarkers = items.map((item) => {
      const icon = L.icon({
        iconUrl: locationIconUri,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
      });
      return L.marker([item.x, item.y], { icon })
        .on("click", () => {
          this.openMarkerModal(item);
        })
        .addTo(this.map);
    });
  }

  openMarkerModal(marker: Marker) {
    const daddy = document.createElement("div");
    document.body.appendChild(daddy);
    render(
      <MarkerModal marker={marker} selfEl={daddy} controller={this} />,
      daddy
    );
  }

  resize(event) {
    this.map.invalidateSize();
  }

  get bounds () {
    if (!this._bounds) {
      const start  = new L.latLng(0, 0);
      const end    = new L.latLng(this.heightValue, this.widthValue);

      this._bounds = new L.latLngBounds(start, end);
    }

    return this._bounds;
  }

  mapTargetConnected(map) {
    this.map;
  }

  get crs() {
    if (!this._crs) {
      const factor = 1 / Math.pow(2, this.maxZoomLevelValue);

      this._crs = L.extend({}, L.CRS.Simple, {
        transformation: new L.Transformation(factor, 0, factor, 0)
      });
    }

    return this._crs;
  }

  layerValueChanged() {
    if (this._layer) {
      this.map.removeLayer(this._layer);
      this._layer = undefined;
    }

    this.layer;
  }

  get height () {
    if (!this._height) {
      this._height = Math.ceil(this.heightValue / 256) * 256;
    }

    return this._height;
  }

  get width () {
    if (!this._width) {
      this._width = Math.ceil(this.widthValue / 256) * 256;
    }

    return this._width;
  }

  get map() {
    if (!this._map) {
      this._map = L.map(this.mapTarget, {
        center: this.bounds.getCenter(),
        maxBounds: this.bounds,
        crs: this.crs,
        zoom: this.maxZoomLevelValue,
        minZoom: 0,
        maxZoom: this.maxZoomLevelValue,
        attributionControl: false,
      });
    }

    return this._map;
  }

  get layer() {
    if (!this._layer) {
      this._layer = L.tileLayer(this.layerValue, {
        minNativeZoom: 0,
        maxNativeZoom: 5,
        noWrap: true,
        bounds: this.bounds,
      }).addTo(this.map);

      L.control
        .attribution({ prefix: false })
        .addAttribution(this.attributionValue)
        .addTo(this.map);
    }

    return this._layer;
  }
}

function MarkerModal({
  marker,
  selfEl,
  controller,
}: {
  marker: Marker;
  selfEl: HTMLDivElement;
  controller: NonGraphicalMapController;
}) {
  const selfDestroy = () => {
    render(null, selfEl);
    selfEl.remove();
  };

  const [commentKey, commentRefresher] = useState(0);
  const refreshComments = () => commentRefresher(commentKey + 1);

  const [comments, setComments] = useState<null | Comment[]>(null);
  const sortedComments = comments?.sort(
    (a, b) => +parseISO(a.created) - +parseISO(b.created)
  );
  useEffect(() => {
    (async () => {
      const { items } = await pb
        .collection<Comment>("comentarios")
        .getList(1, 10000, {
          filter: pb.filter("marcador.id = {:marcador}", {
            marcador: marker.id,
          }),
          expand: "creadorx",
        });
      setComments(items);
    })();
  }, [commentKey]);

  const [user] = useUser();
  const userId = user?.id;

  async function deleteMarker() {
    if (!confirm("¡Atención! Si eliminas el marcador, se borrarán todos los comentarios de tus compañeres. ¿Aún quieres eliminar este marcador?")) return;
    await pb.collection<Marker>("marcados").delete(marker.id);
    await controller.fetchAndAddMarkers();
    selfDestroy();
  }

  return (
    <div class="toggled position-absolute top-0 left-0 w-100 min-h-100vh background-menu pb-5 z-index-modal background-white border border-black shadow-lg">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between m-0 mt-3 p-2">
          <div>
            {marker.creadorx === userId && (
              <button
                type="button"
                class="btn btn-danger"
                onClick={deleteMarker}
              >
                Eliminar marcador
              </button>
            )}
          </div>
          <div class="d-flex m-0 align-items-center no-gutters justify-content-center">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => selfDestroy()}
            >
              <span aria-hidden="true" class="f-40">×</span>
            </button>
          </div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center w-100 mt-3">
          <PostCommentForm marker={marker} refreshComments={refreshComments} />

          <div class="flex-wrap overflow-auto w-100">
            {sortedComments
              ? sortedComments.length > 0
                ? sortedComments.map((c) => (
                    <Comment comment={c} refreshComments={refreshComments} />
                  ))
                : "Todavía no hay comentarios en este marcador."
              : "Cargando..."}
          </div>
        </div>
      </div>
    </div>
  );
}

function PostCommentForm({
  marker,
  refreshComments,
}: {
  marker: Marker;
  refreshComments: () => void;
}) {
  const [posting, setPosting] = useState(false);
  const [texto, setTexto] = useState("");
  const [user] = useUser();
  async function postComment() {
    setPosting(true);
    if (!pb.authStore.model?.id) await loginGoogle();

    try {
      await pb.collection("comentarios").create({
        texto,
        marcador: marker.id,
        creadorx: pb.authStore.model.id,
      });
      setTexto("");
      refreshComments();
    } catch (error) {
      alert(error);
    } finally {
      setPosting(false);
    }
  }
  function _postComment(event: Event) {
    event.preventDefault();
    postComment();
  }
  return (
    <form
      class="d-flex align-items-center justify-content-between w-100 mb-4"
      onSubmit={_postComment}
    >
      <div class="input-group margin-bottom-sm">
        <input
          class="form-control"
          required="required"
          type="text"
          placeholder="Escribir descripción o comentario"
          value={texto}
          onInput={(e) => setTexto((e.target as any).value)}
          disabled={posting}
        />
        {user?.id ? (
          <button class="btn bg-primary text-white" disabled={posting}>
            Enviar
          </button>
        ) : (
          <button class="btn bg-info text-white" disabled={posting}>
            Iniciar sesión y enviar
          </button>
        )}
      </div>
    </form>
  );
}

function Comment({
  comment,
  refreshComments,
}: {
  comment: Comment;
  refreshComments: () => void;
}) {
  const [user] = useUser();
  const userId = user?.id;
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState<false | { texto: string }>(false);

  const wasEdited = comment.created !== comment.updated;

  async function deleteComment() {
    if (!confirm("¿Eliminar comentario?")) return;
    setLoading(true);
    try {
      await pb.collection("comentarios").delete(comment.id);
      refreshComments();
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  async function saveEdit() {
    if (!editing) return;
    setLoading(true);
    try {
      await pb.collection("comentarios").update(comment.id, editing);
      refreshComments();
      setEditing(false);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  function cancelEditing(): void {
    setEditing(false);
  }

  return (
    <div class="d-flex flex-column align-items-center justify-content-center w-100 border border-black p-3 mb-2 rounded">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex flex-column">
          <div class="text-uppercase">
            {comment.expand?.creadorx?.name ||
              comment.expand?.creadorx?.username ||
              comment.creadorx}
          </div>
          <div class="text-primary">
            {formatRelative(parseISO(comment.created), new Date(), {
              locale: es,
            })}
            {wasEdited && (
              <>
                {" "}
                (editado{" "}
                {formatRelative(parseISO(comment.updated), new Date(), {
                  locale: es,
                })}
                )
              </>
            )}
          </div>
        </div>
        {userId && comment.creadorx === userId && (
          <div class="d-flex align-items-center">
            {editing ? (
              <>
                <button
                  type="button"
                  class="btn border-success text-success mx-1"
                  aria-label="Guardar"
                  onClick={saveEdit}
                  disabled={loading}
                >
                  <i class="fa fa-check" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  class="btn border-black text-black mx-1"
                  aria-label="Cancelar"
                  onClick={cancelEditing}
                  disabled={loading}
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  class="btn border-primary text-primary mx-1"
                  aria-label="Editar"
                  onClick={() => setEditing({ texto: comment.texto })}
                  disabled={loading}
                >
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  class="btn border-danger text-danger mx-1"
                  aria-label="Eliminar"
                  onClick={deleteComment}
                  disabled={loading}
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </>
            )}
          </div>
        )}
      </div>
      {editing ? (
        <textarea
          class="my-3 w-100"
          value={editing.texto}
          onInput={(e) => setEditing({ texto: (e.target as any).value })}
          rows={"7"}
        />
      ) : (
        <div class="my-3 w-100 text-left">
          {comment.texto}
        </div>
      )}
    </div>
  );
}

function CreateMarkerOverlay({
  controller,
}: {
  controller: NonGraphicalMapController;
}) {
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    if (isAdding) {
      controller.mapTarget.style.cursor = "crosshair";

      const addMarkerListener = async (event: LeafletMouseEvent) => {
        setIsAdding(false);

        controller.mapTarget.style.cursor = "";

        if (!controller.bounds.contains(event.latlng)) return;

        try {
          const res = await pb.collection("marcados").create<Marker>({
            imagen_uuid: controller.uuidValue,
            x: event.latlng.lat,
            y: event.latlng.lng,
            creadorx: pb.authStore.model?.id,
          });
          await controller.fetchAndAddMarkers();
          controller.openMarkerModal(res);
        } catch (error) {
          alert(error);
        }
      };

      controller.map.on("click", addMarkerListener);
      return () => controller.map.off("click", addMarkerListener);
    }
  }, [isAdding]);

  async function startAdding() {
    if (!pb.authStore.model?.id) {
      await loginGoogle();
    }
    setIsAdding(true);
  }
  function stopAdding() {
    setIsAdding(false);
  }

  return (
    <>
      {isAdding ? (
        <button
          type="button"
          class="position-absolute bottom-0 left-0 w-100 text-white"
          style="background-color: rgba(0,0,0,0.8); font-size: 1em; z-index: 500"
          onClick={stopAdding}
        >
          ¡Apretá donde querés crear tu marcador! O apretá acá para cancelar
        </button>
      ) : (
        <div class="position-absolute bottom-0 right-0 z-index-modal pb-12 pr-12">
          <button
            class="btn btn-primary rounded-5 m-2 px-3"
            onClick={startAdding}
          >
            <div>
              <div class="font-weight-bold f-24">+</div>
              <div>{controller.i18nCreateMarkerValue}</div>
            </div>
          </button>
        </div>
      )}
    </>
  );
}
