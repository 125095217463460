import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { pb } from "../pocketbase";

export default class extends Controller {
  static targets = ["name", "overlay"];

  connect() {
    if (pb.authStore.model) {
      const name = pb.authStore.model?.name;
      this.nameTarget.textContent = `¡Hola ${name}!`;
      this.overlayTarget.display = "none";
    } else {
      const url = new URL(location.origin);
      url.pathname = "/login/";
      url.searchParams.set("from", location.pathname);
      Turbo.visit(url);
    }
  }
}
