import DeviceDetector from "device-detector-js";
import { Notifier } from "@airbrake/browser";

window.deviceDetector = new DeviceDetector();
window.device = window.deviceDetector.parse(navigator.userAgent);

if (!window.device.bot && window.env.JEKYLL_ENV === "production") {
  try {
    window.airbrake = new Notifier({
      projectId: window.env.AIRBRAKE_PROJECT_ID,
      projectKey: window.env.AIRBRAKE_PROJECT_KEY,
      host: "https://panel.sutty.nl",
    });

    console.originalError = console.error;
    console.error = (...e) => {
      window.airbrake.notify(e.join(" "));
      return console.originalError(...e);
    };
  } catch (e) {
    console.error(e);
  }
}

import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "@hotwired/stimulus";
window.Stimulus = Application.start();

import ContactController from "./controllers/contact_controller";
import DeviceDetectorController from "./controllers/device_detector_controller";
import DropController from "./controllers/drop_controller";
import NonGeographicalMapController from "./controllers/non_geographical_map_controller";
import LoadProfileController from "./controllers/load_profile_controller";
import LoginController from "./controllers/login_controller";
import AutofillProfileController from "./controllers/autofill_profile_controller";
import LogoutController from "./controllers/logout_controller";

Stimulus.debug = window.env.JEKYLL_ENV !== "production";
Stimulus.register("contact", ContactController);
Stimulus.register("device-detector", DeviceDetectorController);
Stimulus.register("drop", DropController);
Stimulus.register("non-geographical-map", NonGeographicalMapController);
Stimulus.register("load-profile", LoadProfileController);
Stimulus.register("autofill-profile", AutofillProfileController);
Stimulus.register("login", LoginController);
Stimulus.register("logout", LogoutController);

document.addEventListener("turbo:load", (event) => {
  document
    .querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']")
    .forEach((a) => {
      a.rel = "noopener";
      a.target = "_blank";
    });
});
