import { Controller } from "@hotwired/stimulus";

/*
 * Sólo permite enviar el formulario de contacto después de unos
 * segundos, para evitar el spam.
 */
export default class extends Controller {
  static targets = ["submit"];
  static values = {
    delay: {
      type: Number,
      default: 60,
    },
    submit: String,
    interval: Number,
  };

  submitTargetConnected(submitTarget) {
    submitTarget.disabled = true;
    if (!this.submitValue) this.submitValue = submitTarget.value;
    this.originalDelayValue = this.delayValue;

    // Esperar un minuto desde que se carga la página hasta que se
    // permite enviar.
    this.intervalValue = setInterval(() => {
      const delay = this.delayValue;

      if (this.delayValue == 0) {
        clearInterval(this.intervalValue);
        submitTarget.disabled = false;
        submitTarget.value = this.submitValue;
      } else {
        this.delayValue = delay - 1;
      }
    }, 1000);
  }

  submitTargetDisconnected(submitTarget) {
    this.delayValue = this.originalDelayValue;
  }

  delayValueChanged(newValue, oldValue) {
    if (newValue === oldValue) return;
    if (this.intervalValue === 0) return;

    this.submitTarget.value = `${this.submitValue} (${newValue})`;
  }
}
