// @ts-check
import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { loginGoogle, pb } from "../pocketbase";

export default class extends Controller {
  async loginGoogle() {
    try {
      await loginGoogle();
      const params = new URLSearchParams(location.search);
      let goTo = params.get("from") ?? "/";
      Turbo.visit(goTo);
    } catch (error) {
      alert(error);
    }
  }
}
