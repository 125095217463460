import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { pb } from "../pocketbase";

export default class extends Controller {
  connect() {
    if (pb.authStore.model) {
      const { name, email } = pb.authStore.model;
      if (this.element.estudiante && name) this.element.estudiante.value = name;
      if (this.element.email && email) this.element.email.value = email;
    } else {
      const url = new URL(location.origin);
      url.pathname = "/login/";
      url.searchParams.set("from", location.pathname);
      Turbo.visit(url);
    }
  }
}
