import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { pb } from "../pocketbase.js";

export default class extends Controller {
  logout() {
    pb.authStore.clear();
    Turbo.visit("/");
  }
}
